<template>
  <!-- <transition name="slide"> -->
		<section class="project-inner">
			<div class="container ">

				<div class="project-inner__container container__inner">
					
					<div class="project-inner__content">

					
						<div class="project-inner__title-wr">
							<h1 class="project-inner__title">{{title}}</h1>
							<p class="project-inner__title-decor">{{title.split('')[0]}}</p>
							<p class="project-inner__subtitle">— {{subTitle}}</p>
						</div>

						<div class="project-inner__typography">

							<p>
								<b>
									Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed convallis magna eu sem. Nullam feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec bibendum odio risus sit amet ante.
								</b>
							</p>

							<p>
								Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Praesent id justo in neque elementum ultrices. Etiam bibendum elit eget erat. Duis pulvinar. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
							</p>

							<img src="img/img_01.jpg" class='project-inner__img' alt="product image">
							<img src="img/img_02.jpg" class='project-inner__img project-inner__img--half' alt="product image">
							<img src="img/img_03.jpg" class='project-inner__img project-inner__img--half' alt="product image">

							<p>
								Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Praesent id justo in neque elementum ultrices. Etiam bibendum elit eget erat. Duis pulvinar. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
							</p>

						</div>

						<div class="project-inner__button">
							<a href="">
								Project on archive
							</a>
						</div>

					</div>

				</div>

			</div>
		</section>
  <!-- </transition> -->
</template>


<script>
import {EventBus} from '../EventBus.js';

export default {
	name: 'project__inner',
	data() {
		return {
			title: 'Mivina',
			subTitle: 'Game activation'
		}
	},
	created() {
		// on init page, event no index page 
		EventBus.$emit('on-main-page', {status: false});
	}
}
</script>

<style lang="scss">
</style>